<template>
  <div class="union">
    <PageNav title="产业融资服务合作倡议书" />
    <div class="content">
      <p class="indent">随着国内锅炉市场中以政策面推动项目数量不断减少，大部分地区市场逐渐趋于饱和，少量新项目竞争日趋激烈，加之行业内部从业人员素质参差不齐，大量用户缺乏专业知识，让很多非专业人员以各种非正常手段获取大量项目，不但抢占了很多市场份额，还发生了多起因使用劣质设备或施工工艺不规范导致的项目停工或事故，这在为整个行业敲响警钟的同时也搅乱了整个市场的商业秩序，让很多正规设备制造企业及安装单位受到很大影响订单量骤降，而且大幅提高了销售成本。在这样的市场环境下任何企业都无法独善其身，如坚持单打独斗很容易陷入发展困境或掉入恶性循环陷阱，为了更好的服务于用户并提升行业竞争力，北京祥晨建设有限公司现发起倡议，集结行业内各路知名设备品牌共同组建产业联盟一同应对市场挑战，具体倡议如下：</p>
      <p>一．由我司负责建立联盟合作规则及操作机制，并拟定联盟合作各项法律文件；</p>
      <p>二．以发放正式邀请函形式邀请行业内各知名品牌设备厂商加入联盟；</p>
      <p>三．运作方式主要以将项目设备款及施工费进行拆分，由联盟合作各方根据业务分工进行垫资，使用户以分期付款或租赁的方式付款，拉长付款周期，降低客户一次性付款压力，以这样的形式共同参与项目竞标以提升联盟整体竞争力（此方式类似于汽车或房屋按揭，只是没有银行作为出资方，结款周期会比以往进度款结款方式长）；</p>
      <p>四．此方式的最大优势在于联盟企业各自分头用此模式进行攻关，联盟内企业自由搭配组合，彼此项目数量都会得到显著提升，虽结款周期有所拉长，但可以带来长时间稳定的现金流，如风控处理得当，会在一定程度上改善市场上恶性竞争的现状，达到让联盟企业共同成长的目的；</p>
      <p>五．项目总额拆分周期及具体还款金额，需要根据项目具体情况及联盟企业自有资金情况差异化制定，先签署框架协议，再视项目签署具体合作协议；</p>
      <p>六．由我司建立的锅炉设备网络交易平台“易备网“网址：etb618.com负责收款及催缴服务，并由此平台将收回款项按合同约定比例及时间打到联盟项目合作企业账户；</p>
      <p>七．我司将建立一套完整的目标客户考察流程及风控评分体系，并在设备上安装远程网络监控设备，最大程度控制投资风险并保证联盟企业的合法利益；</p>
      <p>八．根据用户情况不同，设备厂商可选择以分期付款销售或租赁两种模式为用户提供设备；</p>
      <p class="indent">8.1 分期付款销售模式还款计算公式（此种方式涉及设备产权转移及全额发票）：首付+[(含税设备款分期+利息)+(含税施工费分期+利息)]  x 期数+尾款（或）</p>
      <p class="indent">8.2 租赁模式还款计算方式[（此种方式不涉及产权转移，涉及安装及租赁发票（易备平台代开）]：押金+[含税设备款租金+含税施工费分期（包含安装、运输及拆除）+利息] x 期数</p>
      <p>九．联盟企业需申请企业版支付宝以接入“易备”平台；</p>
      <p>十．联盟设备企业需登陆“易备”平台申请账户并上传产品；</p>
      <p>十一．“易备”平台将与支付宝合作为联盟企业提供多项服务：</p>
      <p class="indent">11.1 永久性零交易费用（支付宝提现手续费除外）；</p>
      <p class="indent">11.2 在线宣传下单、签约、盖章、收开发票；</p>
      <p class="indent">11.3 在线追踪物流信息；</p>
      <p class="indent">11.4 在线发布采购及安装需求，在线竞标其他项目采购需求；</p>
      <p class="indent">11.5 应收货款融资等；</p>
      <p>十二．客户私自破坏、拆除、变卖网络监控设备、锅炉及配套设备，项目逾期扽等违约行为的解决方案：</p>
      <p class="indent">12.1 易备网建立资产管理及坏账处理部，为联盟企业解决因项目客户私自变卖或违约导致的一系列侵权问题；</p>
      <p class="indent">12.2 易备网将接入支付宝企业版用户信用评价系统，并通过支付宝将产生坏账的客户上传央行企业征信数据库；</p>
      <p class="indent">12.3 建立侵权连锁反应机制，第一时间通过网络远程断气断电关闭锅炉，迅速派人赶赴现场保护联盟企业资产免遭拆除或变卖；</p>
      <p class="indent">12.4 易备网法务部代表联盟企业，迅速立案起诉违约企业，第一时间向法院申请资产保全，对于拒不执行判决的违约客户，申请将其列为失信被执行人；</p>
      <p class="indent">12.5 法院审理结案后易备网法务部代表联盟企业负责监督催促法院执行进程；</p>
      <p>十三．违约项目资产保全及后续处置方案：</p>
      <p class="indent">13.1 以租赁模式签约项目：易备网将监管的押金中支出拆除费及运输费，由我司指派工作组，将设备返还项目合作联盟设备企业，运输至指定地点，以备下次租赁；</p>
      <p class="indent">13.1.1 以租赁模式签约项目设备方理论上损失只是设备磨损费，保证金除拆除费及运费以外的数额，以及剩余材料（含管道、阀门、电缆等）处置权归安装企业所有；</p>
      <p class="indent">13.2 以分期付款销售模式签约项目：将由我公司负责派遣工作组前往拆除并将设备运至联盟企业共同认定的存放地点，由拆除运回的旧设备有以下几种处理方式：</p>
      <p class="indent">13.2.1 由易备平台负责处置旧设备，处置的设备款全额返还项目合作联盟设备厂商；</p>
      <p class="indent">13.2.2 如3个月未达成处置结果，可由合作联盟设备厂商决定是否继续处置，也可将设备转	为租赁用途或变更为其他处置方式；</p>
      <p class="indent">13.2.3 以分期付款销售模式签约项目中除设备外的一切材料（含管道、阀门、电缆等）处置权归安装企业所有；</p>
      <p>十四．我司认为，故意变卖设备、逾期等侵权行为在前期风控得当的前提下是小概率事件，联盟项目参与企业在此中情况下虽会在一定程度上受到亏损，但只要合作机会大于风险，并且风险在可控范围内，这仍然是一次千载难逢的合作机遇，我司将正视风险，理性面对，尽量控制避免侵权情况发生。如贵公司认同并有意愿加入产业联盟与祥晨建设共同发展，请贵司负责人在邀请函中签字并盖章回函，后续将签署正式的框架协议合作文件。</p>
      <div class="action">
        <van-button type="info" round to="/unionApply">申请加入联盟</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import PageNav from '@/components/PageNav';
export default {
  name: "Union",
  components: { PageNav },
  mounted() {
    window.scrollTo(0,0);
  },
}
</script>

<style lang="scss" scoped>
  .content {
    position: relative;
    padding: 10px 16px 50px;
    p {
      margin-bottom: 1em;
    }
    .indent {
      text-indent: 2em;
    }
  }
  .action {
    position: sticky;
    bottom: 50px;
    text-align: center;
  }
</style>